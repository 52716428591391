import React from 'react'
// import style from './src/tables.css';
import { Input } from '../inputs/Inputs';

interface Props {
  children?: any;
  maxWidth?: string;
  className?: string;
  bgImage?: string;
}

export const Table = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return (
  <div>
    <TableSearch>
      <TableSearchItem>
        
      </TableSearchItem>
      <TableSearchItem className='align-right'>
        <Input className='small' type='text' name='Search' placeholder='Buscar'/>
      </TableSearchItem>
    </TableSearch>
    <table className={`tables ${styleClassName}`}>{children}</table>
  </div>)
}

export const Thead = ({ children }: Props) => {
  return <thead>{children}</thead>
}

export const Tbody = ({ children }: Props) => {
  return <tbody>{children}</tbody>
}

export const Th = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <th className={`th ${styleClassName}`}>{children}</th>
}

export const Tr = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <tr className={`tr ${styleClassName}`}>{children}</tr>
}

export const Td = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <td className={`td ${styleClassName}`}>{children}</td>
}

export const LineDivisor = ({ className }: Props) => {
  return <div className={`line-divisor ${className}`}></div>
}

export const TableSearch = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`table-search ${styleClassName}`}>{children}</div>
}

export const TableSearchItem = ({ children, className }: Props) => {
  const styleClassName = className ? className : '';
  return <div className={`table-search-item ${styleClassName}`}>{children}</div>
}
